import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"
import React from "react"
import SEO from "../components/seo"

const ContactTemplate = ({ data, location, pageContext: { locale } }) => {
  const page = data.prismicContact.data
  const title = page.title.text
  const metaTitle = page.meta_title ? page.meta_title : title
  return (
    <>
      <SEO title={metaTitle} description={page.meta_description} />
      <div className="container">
        <article
          className="contact-page"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h1 itemProp="headline">{title}</h1>
          </header>
          <section itemProp="articleBody">
            <div dangerouslySetInnerHTML={{ __html: page.content.html }} />
          </section>
        </article>
      </div>
    </>
  )
}

export default withPreview(ContactTemplate)

export const pageQuery = graphql`
  query Contact($locale: String!, $uid: String!) {
    prismicContact(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      lang
      data {
        title {
          text
        }
        meta_title
        meta_description
        content {
          html
        }
      }
    }
  }
`
